<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let isApp = vm.$store.state.app;
      //   let isYuu = vm.$store.state.isYuu;
      let query = vm.$route.query;
      let KFCJoinType = localStorage.getItem("KFCJoinType");
      localStorage.setItem("KFCJoinType", 0);

      //   let P="{\"phone\":\"96613727\",\"userId\":\"106321532\",\"storeid\":249,\"app\":true,\"accecode\":\"/m+ek2ZhbLq1WMn7fe+o8j4CWdMb3sr900BbL5FAzkLUXAd0Yd9n17QsrR+PL23g7GsSTABzyrjiYSA/2k84ZgL5c2w5gFUvMkFnZwiADh+b6GfQFJwfDpv4s/LEdNLNCGpo8FZsJ7jyOnoGyHzCKy7rfoO1efNS8ZDKr94CU+o=\",\"sendType\":3,\"deviceId\":\"74856198-7433-4A0A-A4E1-59485CA48D6C\",\"language\":0,\"email\":\"chankwan828@gmail.com\",\"positionInfo\":{\"street\":\"黃埔花園九期百合苑1座\",\"floor\":\"3\",\"longitude\":114.189321,\"address\":\"香港黃埔花園德豐街8號黃埔花園九期百合苑1座\",\"block\":\"2\",\"phone\":\"12345678\",\"flat\":\"A\",\"latitude\":22.303304,\"cityName\":\"香港島\",\"name\":\"test SITU \"}}"
      //   P = encodeURIComponent(P);
      //   vm.$router.push("/orderFood/" + P);
      //   如果没有选择地址或者定位失败 进入地点搜索页面
      if (!vm.$store.state.placeInfo && KFCJoinType == 1) {
        vm.$router.push({
          path: "/selectShop",
          query: {
            type: 1,
            P: query,
            transfer: true,
          },
        });
      } else {
        //   当不是从app跳过来时走这个
        if (!isApp) {
          if (KFCJoinType == 1) {
            vm.$router.push("/orderFood/1");
          } else {
            vm.$router.back();
          }
        } else {
          if (KFCJoinType == 1) {
            if (query.language) {
              if (query.language == 0) {
                query.language = 1;
              }
            }
            let appInfo = vm.$store.state.appInfo;
            let P = {
              app: true,
              sendType: 1,
              deviceId: appInfo.deviceId,
              positionInfo: appInfo.positionInfo,
              language: appInfo.language,
              userId: appInfo.userId || null,
              accecode: appInfo.accecode || null,
              email: appInfo.email || null,
              phone: appInfo.phone || null,
            };
            P = JSON.stringify(P);
            P = encodeURIComponent(P);
            vm.$router.push("/orderFood/" + P);
          } else {
            vm.$router.back();
          }
        }
      }
    });
  },
};
</script>